<template>
    <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
                <v-card class="elevation-12">
                    <v-toolbar color="primary" dark>
                        <v-spacer />
                        <v-toolbar-title>Set Password</v-toolbar-title>
                        <v-spacer />
                    </v-toolbar>
                    <v-form v-model="isFormValid">
                        <v-card-text class="pb-0 mt-2">
                            <v-text-field
                                v-model="password"
                                :append-icon="show ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                                :rules="[rules.required, rules.min, rules.max]"
                                :type="show ? 'text' : 'password'"
                                label="Enter New Password"
                                hint="At least 8 characters"
                                counter
                                outlined
                                @click:append="show = !show" />
                            <v-text-field
                                v-model="rePassword"
                                :append-icon="show1 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                                :rules="[
                                    rules.required,
                                    rules.min,
                                    rules.max,
                                    passwordConfirmationRule,
                                ]"
                                :type="show1 ? 'text' : 'password'"
                                label="Re-enter New Password"
                                hint="At least 8 characters"
                                counter
                                outlined
                                @click:append="show1 = !show1" />
                        </v-card-text>
                        <v-divider />
                        <v-card-actions class="mx-3 py-4">
                            <v-spacer />
                            <v-btn
                                class="px-5 text-none"
                                color="primary"
                                rounded
                                :disabled="!isFormValid"
                                @click="changePassword">
                                Submit
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from "axios";
import { handleError } from "@/services/errorUtility";
export default {
    data() {
        return {
            show: false,
            show1: false,
            password: "",
            rePassword: "",
            isFormValid: false,
            rules: {
                required: value => !!value || "Required.",
                min: v => v.length >= 8 || "Min 8 characters",
                max: v => v.length <= 50 || "Max 50 characters"
            },
        };
    },

    computed: {
        passwordConfirmationRule() {
            return this.password === this.rePassword || "Password must match";
        },
    },

    methods: {
        async changePassword() {
            let response = null;
            try {
                let urlArray = this.$route.fullPath.split("=");
                let body = {
                    Token: urlArray[1],
                    NewPassword: this.password,
                };
                response = await axios.post("api/Security/ResetPassword", body);
            } catch (error) {
                handleError(error);
                return;
            }
            const { dispatch } = this.$store;
            dispatch("authentication/setPasswordSuccess", response.data);
        },
        login() {
            this.$router.push("/login");
        },
        forgotPassword() {
            this.$router.push("/request-reset-password");
        },
    },
};
</script>
